import deselectCurrent from 'toggle-selection';

const isMacOs = /mac os x/i.test(navigator.userAgent)

const promtMessage = `Copy to clipboard: ${isMacOs ? '⌘' : 'Ctrl'} +C, Enter`

function copy(text) {
  const reselectPrevious = deselectCurrent();
  const openedModal = document.querySelector('ds-modal[aria-expanded="true"]')
  const root = openedModal || document.body
  let selection
  let mark
  let range
  try {
    range = document.createRange();
    selection = document.getSelection();

    mark = document.createElement("span");
    mark.textContent = text;
    // avoid screen readers from reading out loud the text
    mark.ariaHidden = "true"
    mark.setAttribute('style', 'position: fixed; transform: translate(-10000px, -10000px);')
    
    root.appendChild(mark);

    range.selectNodeContents(mark);
    selection.addRange(range);

    const successful = document.execCommand("copy");
    if (!successful) {
      throw new Error("copy command was unsuccessful");
    }
  } catch (err) {
    try {
      window.clipboardData.setData('text', text);
    } catch (err) {
      window.prompt(promtMessage, text);
    }
  } finally {
    if (selection) {
      if (typeof selection.removeRange === 'function') {
        selection.removeRange(range);
      } else {
        selection.removeAllRanges();
      }
    }

    if (mark) {
      root.removeChild(mark);
    }
    reselectPrevious();
  }
}

export default copy
